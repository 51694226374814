<template>
    <div class="flex" style="align-items: center;margin-bottom: 2rem;">
        <p class="title_p">{{weightName}}：</p>
        <el-select v-model="weightItem.title" @change="weightFn" placeholder="选择pc权重">
            <el-option v-for="(item, index) in weightList" :key="index" :value="item.title">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        weight: {
            type: [Number, String],
            default: '',
        },
        weightName:String
    },
    name: '',
    created() { },
    mounted() {
        this.weightList.push({
            id: 0,
            title: '无权重'
        })
        for (let i = 1; i < 11; i++) {
            this.weightList.push({
                id: i,
                title: i
            })
        }
        console.log(this.weightList);
        setTimeout(() => {
            this.weightList.forEach((item, index) => {
                if (this.weightList[index].id == this.$props.weight) {
                    this.weightItem = this.weightList[index]
                }
            })
        }, 500);
    },
    data() {
        return {
            weightItem: {},
            weightList: []
        }

    },
    methods: {
        // 获取权重
        weightFn(val) {
            let resultArr = this.weightList.filter((item) => {
                return item.id === val
            })
            this.weightItem = resultArr[0]
            this.$emit('weightFn', this.weightItem.id)

        },
    },
}
</script>

<style lang=scss scoped>
.title_p {
    text-align: right;
    width: 110px;
    font-size: 1.22rem;
}
</style>